





















































@import '~variables'

.header-menu.header-menu--active, .header-menu.header-menu--active:hover
  background-color white
  color black
  font-weight 700

.header-menu__popover
  box-shadow $shadow-elevation-3
  .q-list
    padding 0
    background-color initial
    .q-item
      padding 16px
      color $dark
      text-transform uppercase
      letter-spacing 0.3px
      font-weight 700
      font-size 1em
