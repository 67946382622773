

















































































































@import '~variables'

.notification-menu
  display flex
  height 100%
  color white
  font-size $fs-h2
  cursor pointer
  display flex
  .icon-container
    position relative
    &:hover
      .notification-icon, .icon-badge
        background-color darken($dark, 20%)
    .notification-icon
      display flex
      padding 0 20px
      height 100%
      font-size 25px
    .not-empty
      color $light
    .empty
      color $grey
    .icon-badge
      position absolute
      bottom 3px
      left 38px
      color $light
      background $dark
      border-radius 10px
      padding 4px
      font-size $fs-body

.pop-over
  right 0 !important
  width 500px
  box-shadow none
  .pop-over__list
    padding 0
    background $dark
    .items
      cursor pointer
    .view-more
      text-align center
      padding 10px
      height 40px
      font-weight 600
      font-size $fs-h2
      box-shadow none
      cursor pointer
      background-color white
      color $dark
      &:hover
        background-color $dark
        color white
  .notification-center
    display flex
    background  $dark
    height 40px
    .notification-button
      margin-left auto
      height 100%
      cursor pointer
  .empty
    text-align center
    padding-top 20px
    height 65px
    font-weight 600
    font-size $fs-h2
    box-shadow none
    color Black

