

















































































































































@import '~variables'

.account-menu
  display flex
  align-items center
  height 100%
  color white
  text-transform uppercase
  font-size $fs-h2
  cursor pointer
  .account-menu__title
    display flex
    align-items center
    padding 0 20px
    height 100%
    &:hover
      background-color darken($dark, 20%)

.pop-over__item--lang >>> .q-item-main
  display flex
  align-items center
  .langage-select
    margin-left auto
    width fit-content

.pop-over
  right 0 !important
  left unset !important
  width 350px
  box-shadow none
  .pop-over__list
    padding 0
    background $dark
    .pop-over__item
      padding 16px
      box-shadow none
      color white
      text-transform uppercase
      font-size $fs-h2
      &:hover
        background-color darken($dark, 20%)
        color white
      &.router-link-active
        background-color inherit
        &:hover
          background-color darken($dark, 20%)
      &.user
        background-color black
        cursor default
        .user__avatar
          color white
        .user__name
          text-transform capitalize
          font-size $fs-body
        .user__email
          color $silver
          text-transform none
          letter-spacing 0.3px
          font-size $fs-body
