@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.start-animation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 45000;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 1;
  transition: 2s linear;
}
.start-animation .svg-logo {
  z-index: 200;
  margin: auto;
  margin-top: 6%;
  margin-right: 40vw;
  margin-left: 40vw;
  width: 20vw;
  height: 20vw;
  filter: blur(7px);
  opacity: 0;
  transition: 0.9s ease-in-out;
  transform: scale(0.7);
}
.start-animation .circle-container {
  fill: none;
  stroke: #fff;
  stroke-width: 2px;
}
.start-animation .svg-logo__a {
  transition: stroke-dashoffset 0.5s linear;
  fill: none;
  stroke: #fff;
  stroke-width: 2px;
  stroke-dashoffset: 45vw;
  stroke-dasharray: 160;
  stroke-linecap: round;
}
.start-animation .svg-logo__o {
  opacity: 1;
  fill: none;
  stroke: #fff;
  stroke-width: 2px;
}
.start-animation .loader-title {
  display: flex;
  justify-content: center;
  color: #fff;
  letter-spacing: 3px;
  font-weight: 300;
  font-size: 5rem;
}
.start-animation .q-progress {
  position: absolute;
  bottom: 0px;
  display: block;
  overflow: hidden;
  width: 100%;
  background-clip: padding-box;
}
/*# sourceMappingURL=src/app/pages/launch-animation/index.css.map */