

















































































































@import '~variables'

.dashboard-modal
  font-size $fs-h1
  padding 10px
  .dashboard-modal__header
    display flex
    flex-direction column
    overflow hidden
    padding 16px
    display grid
    place-items center
    font-size $fs-h1
    font-weight bold
  .dashboard-title
    flex-direction column
    padding $space-2
  .create-button
    width 200px
    margin-left auto
    padding-right 10px
