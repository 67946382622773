





























































































@import '~variables'

.start-animation
  position fixed
  top 0
  left 0
  z-index 45000
  width 100%
  height 100%
  background-color black
  opacity 1
  transition 2s linear
  .svg-logo
    z-index 200
    margin auto
    margin-top 6%
    margin-right 40vw
    margin-left 40vw
    width 20vw
    height 20vw
    filter blur(7px)
    opacity 0
    transition 0.9s ease-in-out
    transform scale(0.7)
  .circle-container
    fill none
    stroke white
    stroke-width 2px
  .svg-logo__a
    transition stroke-dashoffset 0.5s linear
    fill none
    stroke white
    stroke-width 2px
    stroke-dashoffset 45vw
    stroke-dasharray 160
    stroke-linecap round
  .svg-logo__o
    opacity 1
    fill none
    stroke white
    stroke-width 2px
  .loader-title
    display flex
    justify-content center
    color white
    letter-spacing 3px
    font-weight 300
    font-size 5rem
  .q-progress
    position absolute
    bottom 0px
    display block
    overflow hidden
    width 100%
    background-clip padding-box
