






































.info-banner
  position fixed
  bottom 0
  left 0
  z-index 1000
  width 100%
  background black
  color white
  font-size 11pt
  .info-banner__container
    position relative
    padding 5px
    width 100%
    height 100%
  .info-banner__message
    display flex
    justify-content center
    align-items center
  .info-banner__close
    position absolute
    right 30px
    bottom 50%
    margin-left auto
    text-decoration underline
    cursor pointer
    transform translateY(50%)
