

















































































































































































































































@import '~variables'

.alert-menu
  display flex
  height 100%
  color white
  font-size $fs-h2
  cursor pointer
  display flex
  .alert-icon
    display flex
    padding 0 20px
    font-size 25px
    &:hover
      background-color darken($dark, 20%)
  .new
    color $warning-on-dark

.pop-over
  right 0 !important
  left unset !important
  width 700px
  box-shadow none
  .pop-over__list
    padding 0
    background $dark
    cursor pointer
    border-left 1px solid black
  .empty
    text-align center
    padding-top 20px
    height 65px
    font-weight 600
    font-size $fs-h2
    box-shadow none
    color Black
    cursor pointer
    border-left 1px solid black
  .view-more
    text-align center
    padding 10px
    height 40px
    font-weight 600
    font-size $fs-h2
    box-shadow none
    background-color white
    color $dark
    &:hover
      background-color $dark
      color white
  .buttons
    background  $dark
    display flow-root
  .alert-center
    float right
    display flex
    background  $dark
    height 40px
    .alert-button
      margin-left auto
      height 100%
      cursor pointer
  .alert-rule-center
    float left
    display flex
    background  $dark
    height 40px
    .alert-rule-button
      margin-left auto
      height 100%
      cursor pointer


.data-table
  font-weight 600
  font-size $fs-h2
  box-shadow none
  background-color white

.high
  background-color #FF3D00
  color white
.normal
  background-color #FFB800
  color white
.low
  background-color #8DCC26
  color white

