@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.alert-menu {
  display: flex;
  height: 100%;
  color: #fff;
  font-size: 1.3rem;
  cursor: pointer;
  display: flex;
}
.alert-menu .alert-icon {
  display: flex;
  padding: 0 20px;
  font-size: 25px;
}
.alert-menu .alert-icon:hover {
  background-color: #38393a;
}
.alert-menu .new {
  color: #ff6347;
}
.pop-over {
  right: 0 !important;
  left: unset !important;
  width: 700px;
  box-shadow: none;
}
.pop-over .pop-over__list {
  padding: 0;
  background: #464749;
  cursor: pointer;
  border-left: 1px solid #000;
}
.pop-over .empty {
  text-align: center;
  padding-top: 20px;
  height: 65px;
  font-weight: 600;
  font-size: 1.3rem;
  box-shadow: none;
  color: Black;
  cursor: pointer;
  border-left: 1px solid #000;
}
.pop-over .view-more {
  text-align: center;
  padding: 10px;
  height: 40px;
  font-weight: 600;
  font-size: 1.3rem;
  box-shadow: none;
  background-color: #fff;
  color: #464749;
}
.pop-over .view-more:hover {
  background-color: #464749;
  color: #fff;
}
.pop-over .buttons {
  background: #464749;
  display: flow-root;
}
.pop-over .alert-center {
  float: right;
  display: flex;
  background: #464749;
  height: 40px;
}
.pop-over .alert-center .alert-button {
  margin-left: auto;
  height: 100%;
  cursor: pointer;
}
.pop-over .alert-rule-center {
  float: left;
  display: flex;
  background: #464749;
  height: 40px;
}
.pop-over .alert-rule-center .alert-rule-button {
  margin-left: auto;
  height: 100%;
  cursor: pointer;
}
.data-table {
  font-weight: 600;
  font-size: 1.3rem;
  box-shadow: none;
  background-color: #fff;
}
.high {
  background-color: #ff3d00;
  color: #fff;
}
.normal {
  background-color: #ffb800;
  color: #fff;
}
.low {
  background-color: #8dcc26;
  color: #fff;
}
/*# sourceMappingURL=src/app/components/layout/header/alert.css.map */