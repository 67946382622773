
























































































































@import '~variables'

.dashboard-menu
  display flex
  height 100%
  color white
  font-size $fs-h2
  cursor pointer
  display flex

.pop-over
  width 350px
  .pop-over__list
    padding 0
    border none!important
    background $dark
    .dashboard-item
      height 100%
      font-weight 600
      font-size $fs-h2
      background white
      box-shadow none
      cursor pointer
    .selected
      background-color gray

.dashboard-command
  display flex
  padding $space-1
  color white
  letter-spacing 1px
  font-weight 300
  .title
    font-size $fs-h2
    cursor default
    display flex
    align-items center
  .dashboard-add-button
    background-color white
    margin-left auto
    align-items center
    font-size $fs-h1
    cursor pointer
    &:hover
      background-color lighten(black, 20%)
      color white

.dashboard-modal
  >>> .modal-content
    display flex
    flex-direction column
    overflow hidden
    padding 16px
    width 900px
