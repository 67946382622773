@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.dashboard-modal {
  font-size: 1.6rem;
  padding: 10px;
}
.dashboard-modal .dashboard-modal__header {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 16px;
  display: grid;
  place-items: center;
  font-size: 1.6rem;
  font-weight: bold;
}
.dashboard-modal .dashboard-title {
  flex-direction: column;
  padding: 16px;
}
.dashboard-modal .create-button {
  width: 200px;
  margin-left: auto;
  padding-right: 10px;
}
/*# sourceMappingURL=src/app/pages/dashboard/components/add-dashboard.css.map */