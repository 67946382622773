@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.account-menu {
  display: flex;
  align-items: center;
  height: 100%;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.3rem;
  cursor: pointer;
}
.account-menu .account-menu__title {
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 100%;
}
.account-menu .account-menu__title:hover {
  background-color: #38393a;
}
.pop-over__item--lang >>> .q-item-main {
  display: flex;
  align-items: center;
}
.pop-over__item--lang >>> .q-item-main .langage-select {
  margin-left: auto;
  width: fit-content;
}
.pop-over {
  right: 0 !important;
  left: unset !important;
  width: 350px;
  box-shadow: none;
}
.pop-over .pop-over__list {
  padding: 0;
  background: #464749;
}
.pop-over .pop-over__list .pop-over__item {
  padding: 16px;
  box-shadow: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.3rem;
}
.pop-over .pop-over__list .pop-over__item:hover {
  background-color: #38393a;
  color: #fff;
}
.pop-over .pop-over__list .pop-over__item.router-link-active {
  background-color: inherit;
}
.pop-over .pop-over__list .pop-over__item.router-link-active:hover {
  background-color: #38393a;
}
.pop-over .pop-over__list .pop-over__item.user {
  background-color: #000;
  cursor: default;
}
.pop-over .pop-over__list .pop-over__item.user .user__avatar {
  color: #fff;
}
.pop-over .pop-over__list .pop-over__item.user .user__name {
  text-transform: capitalize;
  font-size: 1rem;
}
.pop-over .pop-over__list .pop-over__item.user .user__email {
  color: #ddd9d3;
  text-transform: none;
  letter-spacing: 0.3px;
  font-size: 1rem;
}
/*# sourceMappingURL=src/app/components/layout/header/account-menu.css.map */