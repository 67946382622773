@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.oasis-logo {
  display: flex;
  padding: 0 23px 0 22px;
  height: 100%;
  text-decoration: none;
  user-select: none;
}
.oasis-logo:hover .oasis-logo__svg {
  stroke: #f7bbbf;
}
.oasis-logo:hover .oasis-logo__title {
  color: #f7bbbf;
}
.oasis-logo .oasis-logo__svg {
  margin-right: 8px;
  width: 40px;
  height: 100%;
  border: none;
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 30px;
}
@media screen and (max-width: 500px) {
  .oasis-logo .oasis-logo__svg {
    width: 25px;
  }
}
.oasis-logo .oasis-logo__title {
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  font-weight: 300;
  font-size: 1.6rem;
}
@media screen and (max-width: 500px) {
  .oasis-logo .oasis-logo__title {
    font-size: 1.1rem;
  }
}
/*# sourceMappingURL=src/app/components/ui/logos/oasis-logo.css.map */