





































































































@import '~variables'

.notification
  display flex
  height 100%
  font-weight 600
  font-size $fs-h2
  box-shadow none
  .row
    width 100%
    color black
    background-color white
  .title
    width 75%
  .time
    width 25%

