@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.notification-menu {
  display: flex;
  height: 100%;
  color: #fff;
  font-size: 1.3rem;
  cursor: pointer;
  display: flex;
}
.notification-menu .icon-container {
  position: relative;
}
.notification-menu .icon-container:hover .notification-icon,
.notification-menu .icon-container:hover .icon-badge {
  background-color: #38393a;
}
.notification-menu .icon-container .notification-icon {
  display: flex;
  padding: 0 20px;
  height: 100%;
  font-size: 25px;
}
.notification-menu .icon-container .not-empty {
  color: #f3f1ef;
}
.notification-menu .icon-container .empty {
  color: #b4b2a9;
}
.notification-menu .icon-container .icon-badge {
  position: absolute;
  bottom: 3px;
  left: 38px;
  color: #f3f1ef;
  background: #464749;
  border-radius: 10px;
  padding: 4px;
  font-size: 1rem;
}
.pop-over {
  right: 0 !important;
  width: 500px;
  box-shadow: none;
}
.pop-over .pop-over__list {
  padding: 0;
  background: #464749;
}
.pop-over .pop-over__list .items {
  cursor: pointer;
}
.pop-over .pop-over__list .view-more {
  text-align: center;
  padding: 10px;
  height: 40px;
  font-weight: 600;
  font-size: 1.3rem;
  box-shadow: none;
  cursor: pointer;
  background-color: #fff;
  color: #464749;
}
.pop-over .pop-over__list .view-more:hover {
  background-color: #464749;
  color: #fff;
}
.pop-over .notification-center {
  display: flex;
  background: #464749;
  height: 40px;
}
.pop-over .notification-center .notification-button {
  margin-left: auto;
  height: 100%;
  cursor: pointer;
}
.pop-over .empty {
  text-align: center;
  padding-top: 20px;
  height: 65px;
  font-weight: 600;
  font-size: 1.3rem;
  box-shadow: none;
  color: Black;
}
/*# sourceMappingURL=src/app/components/layout/header/notification.css.map */