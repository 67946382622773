@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.dashboard-menu {
  display: flex;
  height: 100%;
  color: #fff;
  font-size: 1.3rem;
  cursor: pointer;
  display: flex;
}
.pop-over {
  width: 350px;
}
.pop-over .pop-over__list {
  padding: 0;
  border: none !important;
  background: #464749;
}
.pop-over .pop-over__list .dashboard-item {
  height: 100%;
  font-weight: 600;
  font-size: 1.3rem;
  background: #fff;
  box-shadow: none;
  cursor: pointer;
}
.pop-over .pop-over__list .selected {
  background-color: #808080;
}
.dashboard-command {
  display: flex;
  padding: 8px;
  color: #fff;
  letter-spacing: 1px;
  font-weight: 300;
}
.dashboard-command .title {
  font-size: 1.3rem;
  cursor: default;
  display: flex;
  align-items: center;
}
.dashboard-command .dashboard-add-button {
  background-color: #fff;
  margin-left: auto;
  align-items: center;
  font-size: 1.6rem;
  cursor: pointer;
}
.dashboard-command .dashboard-add-button:hover {
  background-color: #333;
  color: #fff;
}
.dashboard-modal >>> .modal-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 16px;
  width: 900px;
}
/*# sourceMappingURL=src/app/pages/dashboard/components/dashboard-popup.css.map */