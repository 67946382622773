































@import '~variables'

.oasis-logo
  display flex
  padding 0 23px 0 22px
  height 100%
  text-decoration none
  user-select none
  &:hover
    .oasis-logo__svg
      stroke $primary
    .oasis-logo__title
      color $primary
  .oasis-logo__svg
    margin-right 8px
    width 40px
    height 100%
    border none
    fill none
    stroke white
    stroke-miterlimit 10
    stroke-width 30px
    @media screen and (max-width: 500px)
      width 25px
  .oasis-logo__title
    display flex
    align-items center
    color white
    text-decoration none
    font-weight 300
    font-size $fs-h1
    @media screen and (max-width: 500px)
      font-size $fs-h3
